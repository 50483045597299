<template>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="1112px"
    >
	
	
<!--    <button @click="push"> 开始 </button>
    <button @click="pull"> 拉流 </button>
	 --> 
		 
	<video id="rtc_media_publisher2" width="510" style="left: 1px;" muted autoplay></video> 
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <video id="rtc_media_player" width="510" style="right: 2px;" autoplay controls></video>
	  
	
  </el-dialog>
</template>

<script>
	import $ from "jquery"
  import axios from "axios";  
  export default {
    name: 'ChatRoomPay',
    components: {
    },
    data() {
      return {
        dialogVisible: false,
        title: '去支付11',
        price: '',
        coupons: [
          
        ],
        currentIndex: 0,
        total: 50,
        currentWay: 'wx',
		did:0,
		uid:sessionStorage.getItem('uid')
      }
    },
    computed :{
      couponNum() {
        return this.coupons[this.currentIndex].num
      }
    },
	
 
 
    methods: {
      open(did){
        this.title = '视频通话'
        this.dialogVisible = true ,
		this.did = did
		
		// alert(this.did)
		
		// alert(this.uid)
		
		
		 this.$nextTick(() => {
			 this.push();
			 this.pull();
		 })
	 
      },
	  //视频 测试 推拉 地址
	  //https://webrtc.weimingyl.com/players/rtc_publisher.html?     
	  pull(){
	    var url = "webrtc://webrtc.weimingyl.com/live/uid_"+this.did;
		if (this.player) {
		  this.player.close();
		}
		
		this.player = new SrsRtcPlayerAsync(1);
		
		$("#rtc_media_player").prop("srcObject", this.player.stream);
		 
		this.player
		  .play(url)
		  .then(function (session) {})
		  .catch(function (reason) {
			  console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbb")
		    this.player.close();
		  });  
	  },
	  
	  push(){
	    var url = "webrtc://webrtc.weimingyl.com/live/uid_"+this.uid;
		alert(url);
	    if (this.publisher) {
	      this.publisher.close();
	    }
	    this.publisher = new SrsRtcPublisherAsync(3);
	    $("#rtc_media_publisher2").prop("srcObject", this.publisher.stream);
	  
	    this.publisher
	      .publish(url)
	      .then(function (session) {
	      console.log("aaaaaaaaaaaaaaaaaaaa")
	      })
	      .catch(function (reason) {
	      console.log("111111111111")
	      console.log(reason)
	        this.publisher.close();
	        
	        
	         publisher.close();
	                        $('#rtc_media_publisher').hide();
	                        console.error(reason);
	                        
	                        
	      });
	  },
	  
	  
	   
    }
  }
</script>

<style scoped lang="scss">
  .main{
    .title{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      padding-bottom: 14px;
    }
    .el-input{
      margin-bottom: 90px;
    }
    .price{
      font-size: 18px;
      color: #333;
      padding-left: 20px;
      span{
        font-size: 40px;
      }
    }
    .tip{
      font-size: 12px;
      color: #FF1515;
      line-height: 17px;
      padding: 18px 0 59px;
    }
    .pay_text{
      color: #999;
      font-size: 18px;
      margin-bottom: 30px;
      &>span{
        margin-right: 48px;
      }
      .red{
        color: #FF2828;
        span{
          font-size: 40px;
        }
      }
    }
    .coupon_wrap{
      margin-bottom: 17px;
      width: 100%;
      height: 80px;
      .coupons{
        display: flex;
        height: 56px;
        .coupon{
          flex-shrink:0;
          width: 150px;
          height: 56px;
          margin-right: 24px;
          background-color: $green;
          font-size: 12px;
          color: #fff;
          text-align: center;
          line-height: 18px;
          padding-top: 4px;
          .coupon_num{
            font-size: 14px;
            padding-right: 10px;
          }
          .red{
            background-color: #FF2828;
            display: inline-block;
            position: relative;
            bottom: -2px;
            padding: 0 4px;
          }
        }
        .choosed{
          background-color: #A5E3E7;
        }
      }

    }
    .payWays{
      margin-bottom: 90px;
      display: flex;
      .wayItem{
        height: 55px;
        margin-right: 40px;
        position: relative;
        i{
          position: absolute;
          bottom: 10px;
          right: 0;
          font-size: 21px;
          color: #FF2828;
      }
      }
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 14px 20px;
    .el-input__prefix{
      left: 20px!important;
      line-height: 48px;
      font-size: 12px;
      color: #333;
    }
    .el-input--medium .el-input__inner{
      text-indent: 18px;
      height: 48px;
    }
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    padding-bottom: 50px;
    .el-button--primary{
      width: 190px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
  /deep/ .el-scrollbar__wrap{
    overflow-y: hidden!important;
  }
</style>
