<template>
	<el-dialog title="支付" :visible.sync="dialogVisible" width="532px">


		<div class="gifts clearFix" style="text-align:center">
			<div style="float: left; width:50%">
				<img :src="this.wx">
				<p>微信</p> 
			</div>

			<div style="float: left; width:50%">
				<img :src="this.zfb">
				<p>支付宝</p>
			</div>
		</div>
		<br/>
		<br/>


	</el-dialog>


</template>

<script>
	import $ from "jquery"
	import axios from "axios";
	import data from '@/data/data.js'
	import Pay from './Pay.vue'
	import EditsInfo from '@/components/EditsInfo'
	import dialogHeader from '@/components/Headers'

	export default {
		name: "AddShowPayInfo",





		components: {
			Pay,
			EditsInfo,
			'dialog-header': dialogHeader,
		},
		props: ['info'],
		data() {
			return {
				wx:'',
				zfb:'',
				screenHeight: 400, // 屏幕高度
				isFullscreen: false,
				big: 0,
				messageToHelloWorld: '我是父组件向下传递的信息',
				id: 0,
				dialogVisible: false,
				gifts: [],
				index: 0,
				checkedGift: {
					id: 60,
					uid: 60,
					name: '玫瑰花',
					url: require('../assets/gifts/5.jpg'),
					bid: 0,
					num: 0,
					message: '',

				},
				finfo: {},
				num: 1,
				textarea: '',
				temps: [],
				contents: [],
				case_id: 0,
				ids: 0,
				type: 0,
			};
		},
		created() {



		},

		watch: {
			info: function(newVal, oldVal) {
				this.finfo = newVal; //newVal即是chartData
				this.drawChart();
			}
		},
		mounted() {
			//this.formatGifts(data.gifts)

			//礼物列表 


		},
		methods: {

			//是否为全屏函数
			heightChange(e) {
				if (e) {

					this.screenHeight = document.documentElement.clientHeight - 50;
				} else {
					this.screenHeight = 400;
				}
			},

			save() {

				const params = new URLSearchParams();
				params.append('data', JSON.stringify(this.temps));
				params.append('id', this.case_id);
				params.append('type', this.type);

				axios.post(this.apiUrl + '/api/Home/saveCaseTemp', params)
					.then((response) => {

						this.$message.success('操作成功!');

					})
					.catch(function(error) {
						console.log(error);
					});


			},
			gi_live(i) {

				//$("#"+i).css({"height":"20px"});
			},
			gi(i) {
				//$(".infoC").css({"height":"50px"});
				//$("#"+i).css({"height":"200px"});
				//$("#"+i).css({"border-color":"red!important"});

			},
			//是否为全屏函数
			onFullscreen(fullscreen) {
				this.isFullscreen = fullscreen;
			},
			//关闭事件
			dialogClose() {
				this.dialogVisible = false;
			},

			goDel() {
				console.log("zzzzzzzzzzzzzzzzzzzzzzz" + this.case_id);
				console.log(this.contents);
				//alert(this.contents['value'])
				//this.a.$remove('222'); 
				for (var i = 0; i < this.contents.length; i++) {
					for (var i2 = 0; i2 < this.temps.length; i2++) {
						if (this.temps[i2]['value'] == this.contents[i]['value']) {

							this.temps.splice(i2, 1);
						}
					}

				}


				// const params = new URLSearchParams();
				// params.append('tem_id', this.case_id); 
				// params.append('content', this.contents); 

				// axios.post(this.apiUrl+'/api/User/delCaseInfo', params)
				// .then((response) => { 	

				// 	this.temps =  response.data.data;	
				// 	//this.$parent.$parent.temps =  response.data.data;

				// })
				// .catch(function(error) {
				//   console.log(error);
				// });




			},
			// 标签滚动
			goAnchor(selector) {
				this.$el.querySelector(selector).scrollIntoView({
					behavior: "smooth", // 平滑过渡
					block: "start" // 上边框与视窗顶部平齐。默认值
				});
			},
			add() {

				let newList = {
					id: ' ',
					value: ' '
				}

				//this.temps.unshift(newList);
				this.temps.push(newList);

				$(".infoC").css({
					"height": "20px"
				});
				// $("#"+i).css({"height":"200px"});
				// $("#"+i).focus().select();
				//this.$refs.EditsInfo.open(this.case_id);

				//this.temps.push("asdfas")
				this.goAnchor('#box');
				//document.getElementById('box').scrollIntoView();
				// var h4 = $('.out-box').prop("scrollHeight"); //等同 $('.out-box')[0].scrollHeight
				// alert(h4+300);
				// $('.out-box').scrollTop(h4);


			},
			goSet() {

				console.log(this.contents);
				var str = ";"
				for (var j = 0, len = this.contents.length; j < len; j++) {
					str += this.contents[j] + "  ";
				}
				this.$parent.textarea = str;
				this.dialogVisible = false;

			},



			gohandleSend2() {
				//alert(3);
			},


			open(wx,zfb) {
				this.wx = wx
				this.zfb = zfb

				this.dialogVisible = true
			},
			handleSend3() {
				this.checkedGift.uid = sessionStorage.getItem('uid');
				this.checkedGift.bid = this.finfo.id;
				this.checkedGift.num = this.num;
				this.checkedGift.message = this.textarea;



				this.$refs.pay.open()
			},
			handleSend2() {
				const that = this
				this.$confirm('确认赠送？')
					.then(() => {
						console.log("++++++++")
						console.log(this.checkedGift)


						const params3 = new URLSearchParams();

						params3.append('uid', sessionStorage.getItem('uid'));

						params3.append('bid', this.id);

						params3.append('name', this.checkedGift.name);

						params3.append('url', this.checkedGift.url);

						params3.append('num', this.num);

						params3.append('message', this.textarea);


						console.log(params3);

						axios.post(this.apiUrl + '/api/user/giveGift', params3)
							.then((response) => {

								this.gifts = response.data.data;

							})
							.catch(function(error) {
								console.log(error);
							});



					})
					.catch(() => {});
			},
			handleSend() {
				const that = this
				this.$confirm('确认赠送？')
					.then(() => {
						that.dialogVisible = false
					})
					.catch(() => {});
			},
			formatGifts(data) {
				let num = Math.ceil(data.length / 12)
				this.gifts = new Array(num)
				for (let i = 0; i < num; i++) {
					this.gifts[i] = data.slice(i * 12, i * 12 + 12);
				}
			},
			check(item) {
				this.checkedGift = item
			}
		}
	}
</script>

<style scoped lang="scss">
	.gifts {
		.title {
			margin-bottom: 21px;
			color: #333;
		}

		.gift {
			width: 84px;
			height: 84px;
		}

		.el-icon-success {
			position: absolute;
			bottom: 5px;
			right: 50%;
			transform: translateX(42px);
			font-size: 17px;
			color: #FF2D2D;
		}

		.el-pagination {
			text-align: center;
		}
	}

	.info {
		&>div {
			margin-bottom: 10px;
		}
	}

	/deep/ .el-dialog__header {
		background-color: $green;
		height: 33px;
		padding: 0 0 0 27px;

		.el-dialog__title {
			line-height: 33px;
			font-size: 12px;
			color: #fff;
		}

		.el-dialog__headerbtn {
			top: 8px;

			.el-dialog__close {
				color: #fff;
			}
		}
	}

	/deep/ .el-dialog__body {
		// padding: 18px 16px;
		padding: 0px;
	}

	/deep/ .el-dialog__footer {
		text-align: center;

		.el-button--primary {
			width: 95px;
			height: 32px;
			background-color: $green;
			border-color: $green;
		}
	}

	/deep/ .el-pager li:last-child {
		border-color: $green
	}

	.el-col-4 {
		text-align: center;
		position: relative;
		margin-bottom: 16px;
	}

	.el-input-number--mini {
		width: 74px;
		line-height: 22px;
	}

	/*/deep/ .el-input__inner{*/
	/*  border: unset;*/
	/*}*/
	.el-input-number__decrease,
	.el-input-number__increase {
		background-color: transparent;
	}

	/deep/ .el-input--mini .el-input__inner {
		padding-left: 0;
		padding-right: 0;
	}

	/deep/ .el-input--mini .el-input__inner {
		height: 24px;
	}

	/deep/ .el-input-number--mini .el-input-number__decrease,
	/deep/ .el-input-number--mini .el-input-number__increase {
		width: 20px;
	}
</style>