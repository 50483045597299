<template>
	<div class="chatRoot">
		<ScrollNotice />
		<div class="chatWrap">
			<!--聊天主界面-->
			<div class="chatMain">
				<!--头部信息-->
				<div class="head">
					<div class="avatar">
						<img :src="this.apiUrl+info.pic" style="width: 100%; height: 100%">
					</div>
					<div class="info">
						<el-row>
							<el-col :span="24">
								<span class="nick">{{info.nickname}}</span>
								<span class="name" @click="show_Pay_info" >真实姓名: {{info.name}}</span>
								<span class="id">(ID: {{info.ids}})</span>
								<span class="lastTime">上次登录时间:{{info.last_login}}</span>
							</el-col>
							<el-col :span="8" v-if="isDoctor">
								<div class="rate_item">
									<span>技术满意度： </span>
									<el-progress :percentage="100" :format="format1"></el-progress>
								</div>
								<div class="rate_item">
									<span>文明满意度： </span>
									<el-progress :percentage="100" :format="format2"></el-progress>
								</div>
							</el-col>
							<el-col :span="16" v-if="isDoctor">
								<p class="info_detail">
									性别：{{info.sex === 1 ? '男' : '女'}}；年龄：{{info.age}}岁；
									<!-- 民族：{{this.races_list[info.race+1]['name'] }}；
									国籍：{{this.nationalities_list[info.nationality+1]['name']}}； -->
									专业：{{ info.departmentList_name }}；
								</p>
								<p class="info_detail">
									<!-- 学历：{{this.educations_list[info.education+1]['name']}}； -->

									<!--籍贯：{{info.hometown_name}}；常住地址：{{info.address}} -->

								</p>
							</el-col>
							<el-col :span="24" v-if="isDoctor">
								<p>个人介绍：{{info.resume}}</p>
							</el-col>
						</el-row>
					</div>


					<div class="videoCall">
						<div class="video" style="text-align: center;cursor:pointer;" @click="goLive">
							<i class="el-icon-video-camera" style="font-size: 32px;line-height: 96px"></i>
						</div>
						<div class="nav" style="width:360px;">
							<span class="nav_item" @click="" v-if="this.info.isCollected">已收藏</span>
							<span class="nav_item" @click="saveCollect2" v-else>收藏</span>
							<el-divider direction="vertical" />
							<span class="nav_item" @click="sendGifts">送礼物</span>
							<el-divider direction="vertical" />
							<span class="nav_item" @click="goEvaluate">去评价</span>
							<el-divider direction="vertical" />

							<span class="nav_item" @click="" v-if="this.info.isCollected">已阻止</span>
							<span class="nav_item" @click="setBlock" v-else>阻止</span>

							<el-divider direction="vertical" />
							<span class="nav_item" @click="goReport" v-if="this.info.isReport">已举报</span>
							<span class="nav_item" @click="goReport" v-else>举报</span>

						</div>
					</div>
				</div>
				<!--医生附带头部信息-->
				<!--  <div class="appendHead">
          <div class="appendAvatar">
            <el-image :src="require('../../assets/avatar/习春.jpg')" fit="cover" style="width: 100%; height: 100%"></el-image>
          </div>
          <div class="appendInfo">
            <div class="ellipsis">
              <span style="font-size: 16px;font-weight:600;color:#333;padding-right: 18px">王医生</span>
              性别：男；年龄：36岁；民族：汉族；国籍：中国；身高：175cm；体重：88kg；学历：本科；职业：农民；婚姻：未婚；籍贯：山东济南；病史陈诉者：个人；是否可靠：是。
            </div>
            <div>
              <span style="margin-right: 46px">家庭住址：山东省济南市槐荫区XXXXX</span>
              现住址：山东省济南市槐荫区XXXXX
            </div>
            <div>
              个人自白：腿部疼痛，时间有一年多了，吃了药都不见好转！
            </div>
          </div>
        </div> -->
				<!--警告-->
				<div class="warning">
					<i class="el-icon-info"></i>
					加强自我防范意识，警惕各种形式的奇方妙药
				</div>
				<!--消息展示框-->
				<div class="newsWrap" @click="stopT()"  ref="imageTofile">
					<!--循环生成消息列表，收到消息class为left，发送为right-->
					<!-- <scroll-view   ref="scroll" style="height:100%"   > -->
						<div class="newsList chat-box-content"   style="overflow:auto; height:500px; padding: 0 30px;"    :class="{newEnd: isEnd}"    >
							
							<span class="more_msg"
								style="color:#39d2dd;width: 100% ;    margin-top: 30px; margin-left:46%;cursor: pointer;"
								@click="historyLog()"> 更多聊天记录</span>


							<div v-for="(item,index) in all_data"  ref="myDiv">


								<span :class="'mes_'+index" type="text" style="display:none"> {{item.mess}} </span>

								<input class="weui-check del_all check_all" type="checkbox" name="checkbox1"
									style="display:none" :class="'del_'+index" :value="index" v-model="checkedValue" />

								<div style="text-align: center; background-color:  ;" class="del_all"
									:class="'del_'+index">

									<div v-if="item.local==0">

										<div v-if="item.show_type!=6" class="news left" :id='item.ids'>
											<el-image class="news_avatar" :src="did_img" />
											<div class="news_content">
												<span class="time"> {{item.time}}</span>

												<span v-if="item.show_type==1" class="text" v-html="item.mess"></span>

												<span v-if="item.show_type==2" class="text"><img :src='item.mess'
														width=100>
												</span>

												<span v-if="item.show_type==3" class="text" @click="addCaseRecord"><img
														:src='item.mess' width=100> </span>
														
												<span v-if="item.show_type==8" class="text" @click="show_Pay_info(item.wx,item.zfb)">
														
														<el-button type="primary"  >支付{{item.mess}}元</el-button>
												</span>


											</div>
										</div>

										<span v-if="item.show_type==6"
											style="color:#39d2dd;width: 100% ; text-align:center; margin-top: 30px;">用户支付成功,用户可正常聊天</span>

										<!-- 		<span v-if="item.show_type==7" class="more_msg"
											style="color:#39d2dd;width: 100% ; text-align:center; margin-top: 30px;">1更多聊天记录</span>
 -->

									</div>


									<div v-if="item.local==1">
										<div v-if="item.show_type!=6" class="news right" :id='item.ids'>
											<el-image class="news_avatar" :src="id_img" />
											<div class="news_content">
												<span class="time"> {{item.time}}</span>

												</span>

												<span v-if="item.show_type==1" @contextmenu.prevent="delImg(item.ids)"
													class="text reght_css" v-html="item.mess"> </span>

												<span v-if="item.show_type==2" @contextmenu.prevent="delImg(item.ids)"
													class="text reght_css"><img :src='item.mess' width=100> </span>

												<span v-if="item.show_type==3" @contextmenu.prevent="delImg(item.ids)"
													class="text reght_css" @click="addCaseRecord"><img :src='item.mess'
														width=100> </span>



												<span v-if="item.show_type==8" class="text" @click="show_Pay_info(item.wx,item.zfb)">
														
														<el-button type="primary"  >支付{{item.mess}}元</el-button>
											</span>
											</div>
										</div>

										<span v-if="item.show_type==6"
											style="color:#39d2dd;width: 100% ; text-align:center; margin-top: 30px;">用户支付成功,用户可正常聊天</span>

										<!-- 		<span v-if="item.show_type==7" class="more_msg"
											style="color:#39d2dd;width: 100% ; text-align:center; margin-top: 30px;cursor: pointer;" @click="historyLog()">更多聊天记录</span>
 -->

									</div>

								</div>

							</div>
							<!--结束前选项-->
							<div class="beforeEnd" v-if="isEnd">
								<p>本次咨询即将结束，请选择</p>
								<div class="navBtn">
									<el-button>
										1.继续免费咨询1小时
									</el-button>
								</div>
								<div class="navBtn">
									<el-button>
										2.按首次咨询后7日内半价，收取咨询费
									</el-button>
								</div>
								<div class="navBtn">
									<el-button>
										3.按10小时内不再次收取咨询费继续咨询，最晚至每日晚7点
									</el-button>

								</div>
							</div>
						</div>
						<!--结束前选项-->
						<div class="beforeEnd" v-if="isEnd">
							<p>本次咨询即将结束，请选择</p>
							<div class="navBtn">
								<el-button>
									1.继续免费咨询1小时
								</el-button>
							</div>
							<div class="navBtn">
								<el-button>
									2.按首次咨询后7日内半价，收取咨询费
								</el-button>
							</div>
							<div class="navBtn">
								<el-button>
									3.按10小时内不再次收取咨询费继续咨询，最晚至每日晚7点
								</el-button>

							</div>
							
							
							 
						</div>
					<!-- </scroll-view >
					 -->
					
				</div>
				<!--快捷工具条-->
				<div class="send_tools_bar">
					<!--快捷输入工具按钮-->
					<div class="left_tools" style="margin-top:10px;  ">
						
						<div    style="background:white; max-width:500px; overflow-x: auto; white-space: nowrap;">
							<!--循环生成聊天模板-->
							<div class="" style="line-height:0px;cursor:pointer;display: inline-block; "
								v-for="(chatTemplate, index) in chatTemplates" @click.stop="">
								<img class="icon8" src="../../assets/images/mb.png" style="po" width="26"
									@click.stop="addPatient(chatTemplate.id)"  @contextmenu.prevent="addPatient2(chatTemplate.id)"alt="">
								<br />
								<span style="font-size: 11px;margin-left:37px;margin-top:120px;">{{chatTemplate.name}}</span>
							</div>
						</div>


						<!--添加聊天模板-->
						<div     class="left_tools_item" style="cursor:pointer;" v-if="show_pay && chatTemplates.length < 20"
							@click="addTemplate">
							<img class="icon8" src="../../assets/images/add.png" width="26" alt="">
						</div>
						<!--发送图片-->

						<!--表情包-->
						<div class="left_tools_item" style="cursor:pointer;">
							<i class="el-icon-picture-outline-round1" @click="emotStart()">
								<img class="icon8" src="../../assets/images/bq.png" style="po" width="26" alt="">
							</i>

							<div class="chatTemplate" v-if="emot" style="position: absolute;
																width: 522px;
																height: 155px;
																background: #F4FEFF;
																border: 1px solid #76D8DF;
																bottom: 55px;
																left: 10px;
									">
								<span v-for="(items, index) in emoj_list2">
									<span style="margin-left:10px;cursor:pointer" @click="goEmoj(items.url)"
										v-html="items.url"> </span>
								</span>


								<!-- 	<span style=""   v-for="(items, index) in emoj_list" > 
									 
										<el-image :src="items.url"  @click="goEmoj(index)"	  class="avatar" style="width:18px;margin: 10px;line-height:1px!important;"></el-image> 
									
									</span> -->



							</div>

						</div>
						<!--发送图片-->
						<div class="left_tools_item" style="cursor:pointer;">
							<el-upload class="avatar-uploader" action="#" :show-file-list="false" accept="image/*"
								:http-request="upload">
								<img v-if="imageUrl" :src="imageUrl" class="avatar">
								<img class="icon8" src="../../assets/images/img.png" style="po" width="26" alt="">
							</el-upload>
						</div>
						<!--发送视频通话-->
						<div class="left_tools_item" @click="goLive" style="cursor:pointer;">
							<img class="icon8" src="../../assets/images/sp.png" style="po" width="26" alt="">
						</div>
						<!--发送语音通话-->
						<div class="left_tools_item" @click="goAudio" style="cursor:pointer;">

							<img class="icon8" src="../../assets/images/tel.png" style="po" width="26" alt="">
						</div>
						<div class="left_tools_item" style="cursor:pointer;">
							<!--资金提示图标-->
							<img class="icon8" src="../../assets/images/pay.png" width="26" alt="" @click="handlePay">
						</div>
					</div>
					<!--快捷病例按钮-->
					<!--    <div class="right_tools">
            <i class="el-icon-postcard" @click="openRecord"></i>
          </div> -->


					<div class="right_tools">
						<!--快捷住院病例按钮-->
						<img src="../../assets/images/mzhuyuan.jpg" style="cursor:pointer;" width="26" alt=""
							@click="addCaseRecord(2)">
						&nbsp;
						<!--快捷门诊病例按钮-->
						<img src="../../assets/images/mzhuyuan.png" width="26" style="cursor:pointer;" alt=""
							@click="addCaseRecord(1)">
						<!-- <i style="margin-left: 30px;" class="el-icon-postcard" @click="addCaseRecord(1)"></i> -->
					</div>


				</div>





				<div style="background-color:#F7F7F7" class="send_tools_bar">
					<div style="float: left; margin-left: 100px;;">

						<i class="el-icon-chat-1line-square" @click="goInitialTemp(1)" style="cursor:pointer;">
							<img src="../../assets/images/mb.png" class="avatar" @click="goInitialTemp(1)"
								style="width:22px;	margin-top:10px;">
							初始模板 </i>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<i class="el-icon-chat-line-square1" @click="goLableTemp(2)" style="cursor:pointer;">
							<img src="../../assets/images/mb.png" class="avatar" @click="goLableTemp(2)"
								style="width:22px;margin-top:10px;">
							标签模板 </i>

						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<!-- 	<i class="el-icon-chat-line-square" @click="goSearchDisease(2)"> 搜索疾病名称</i> -->
						<el-button
							style="border-radius: 16px !important;border-color:#36CDD8;background:white!important; color:#36CDD8!important;border-radius:; "
							@click="goSearchDisease(2)">搜索疾病名称</el-button>


						<!-- <div style="width:100px;height:10px; background:red" >搜索疾病名称</div>
 -->
					</div>
				</div>
				<!--聊天输入框-->
				<div class="input_wrap" @click="stopT()">


					<el-input v-if="!show_pay" type="textarea" :readonly="true" :rows="10" resize="vertical"
						:autofocus="true" v-model="textarea" @keyup.enter.native="sendMsgToServer()"  >

					</el-input>


					<el-input v-if="show_pay" type="textarea" :rows="10" resize="vertical" :autofocus="true"
						v-model="textarea" @keyup.enter.native="sendMsgToServer()"  >


					</el-input>




					<div class="send">
						<!-- 住院病理 -->
						<img src="../../assets/images/zy.png" class="avatar" @click="saveZhuYuan()"
							style="width:38px;cursor:pointer;">

						&nbsp;
						<!-- 门诊病理 -->
						<img src="../../assets/images/mz.png" class="avatar" @click="saveMenzhen()"
							style="width:38px;cursor:pointer;">
						&nbsp;
						<small>按Enter键发送，按Ctrl+Enter键换行</small>
						<el-button type="primary" @click="copyMsgToServer">复制</el-button>
						<el-button type="primary" @click="cleanMsgToServer">清空</el-button>
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						&nbsp;
						<el-button type="primary" @click="sendMsgToServer">发送</el-button>
					</div>
				</div>
			</div>
			<div class="chatSide">
				<div class="side_top">
					<div class="pre_next">
						<el-button-group>
							<el-button type="primary" icon="el-icon-message" @click="goMessage()">收件箱</el-button>
							<el-button type="primary" icon="el-icon-caret-top" @click="goMesBoxUp">上一位</el-button>
							<el-button type="primary" icon="el-icon-close"
								@click="goCleanBox()">删&nbsp;&nbsp;&nbsp;&nbsp;除</el-button>
							<el-button type="primary" icon="el-icon-caret-bottom" @click="goMesBoxNext">下一位</el-button>
						</el-button-group>
					</div>
					<div class="timeCount">
						<el-button type="primary" style="font-size: 18px;padding:0">{{Locktime}}</el-button>
					</div>
				</div>
				<div class="side_middle">
					<el-button-group>
						<!--<el-button type="primary">单选</el-button> -->
						<el-button type="primary" @click="moreChange()">多选</el-button>
						<!-- <el-button type="primary">片选</el-button> -->
						<el-button type="primary" @click="allMore()">全选</el-button>
						<el-button type="primary" @click="copyMore()">复制</el-button>
						<el-button type="primary" @click="moreDel()">删除</el-button>
						<el-button type="primary" @click="allClean()">清空</el-button>
						<el-button type="primary" @click="toImage()">截屏</el-button>
					</el-button-group>
				</div>
			</div>
		</div>
		<!--    <Pay ref="pay"/>
    <Record ref="record"/>
	 -->

		<ChatLive ref="ChatLive" :info="info" />

		<ChatAudio ref="ChatAudio" :info="info" />


		<SearchDisease ref="searchDisease" />



		<WorldNameCaseInfo ref="WorldNameCaseInfo" @fatherMethod="getInfo" :info="info" />
		<ChatRoomPay @sendPayImg='getPayImg' ref="ChatRoomPay" />
		<Gifts ref="gifts" :info="info" />
		<Schedual ref="schedual" />

		<Evaluate ref="Evaluate" :info="info" />
		<ChatRoomAddCaseRecord ref="ChatRoomAddCaseRecord" :info="info" />
		<Report ref="Report" :info="info" />

		<Comment ref="Comment" :info="info" />
		<CaseInfo ref="CaseInfo" :info="info" />
		<CaseODInfo ref="CaseODInfo" :info="info" />

		<LabelInfo ref="LabelInfo" :info="info" />

		<InitialTemp ref="InitialTemp" :info="info" />


		<ChatRoomInitialTemp ref="ChatRoomInitialTemp" :info="info" />
		<ChatRoomLableTemp ref="ChatRoomLableTemp" :info="info" /> 
		<ImgCaseInfo ref="ImgCaseInfo" @goSendImgs="goSendImgs(e,file_id)" :info="info" />
		<ImgNameCaseInfo ref="ImgNameCaseInfo" @fatherMethod="getInfo" :info="info" />
		<ChatRoomMenZhen ref="ChatRoomMenZhen" :info="info" />



		<WorldCaseInfo ref="WorldCaseInfo" :info="info" />
		<WorldNameCaseInfo ref="WorldNameCaseInfo" @fatherMethod="getInfo" :info="info" />
		
		<ShowPayInfo ref="ShowPayInfo" />
		


	</div>
</template>

<script>
	
	
		
	import $ from "jquery"
	import html2canvas from "html2canvas";
 
	import axios from "axios";
	import io from 'socket.io-client';

	const socket = io('http://8.140.12.83:9515');
	import SockJS from 'sockjs-client';
	import Stomp from 'stompjs';
	import WorldNameCaseInfo from '@/components/AddWorldNameCaseInfo';

	import WorldCaseInfo from '@/components/AddWorldCaseInfo';
	import SearchDisease from '@/components/SearchDisease'

	import chatTemplate from '@/utils/chatTemplate'
	import ScrollNotice from "../../components/ScrollNotice"
	import ChatRoomPay from '@/components/ChatRoomPay'
	import Record from './Record'
	import {
		tio
	} from '../../utils/tiows'

	import CaseODInfo from '@/components/AddODCaseInfo'
	import CaseInfo from '@/components/AddCaseInfo'
	import LabelInfo from '@/components/AddLabelInfo'


	import ChatRoomAddCaseRecord from '@/components/ChatRoomAddCaseRecord'
	import ChatRoomInitialTemp from '@/components/ChatRoomInitialTemp'
	import ChatRoomLableTemp from '@/components/ChatRoomLableTemp'
	import ChatRoomMenZhen from '@/components/ChatRoomMenZhen'

	import ChatLive from '@/components/ChatLive'
	import ChatAudio from '@/components/ChatAudio'

	import Gifts from '@/components/Gifts'
	import Evaluate from '@/components/Evaluate'
	import Report from '@/components/Report'
	import Comment from '@/components/Comment'
	import Schedual from '@/components/Schedual'

	import ImgCaseInfo from '@/components/AddImgCaseInfo';
	import ImgNameCaseInfo from '@/components/AddImgNameCaseInfo';
	import ShowPayInfo from '@/components/AddShowPayInfo';



	import {
		getMemo
	} from "../../api/DoctorApi";
	import {
		saveCollect
	} from "../../api/EvalApi";





	$(document).ready(function() {

		function aa() {
			app.fn();
		}


		$("body").on("mousedown", ".rightClick", function(e) {

			app.fn();


			if (e.which == 3) {
				if (confirm("确定撤回此条信息?")) {
					var id_del = $(this).parent("div").parent("div").attr("id");
					//alert(id_del)
					//点击确定后操作
					$(this).parent("div").parent("div").hide();
					var goMes = 33 + '_' + 2 + '_' + 222 + '_4';
					//alert(goMes)
					socket.emit('hello', goMes);
				}
			}



		})

		$("body").on("click", ".case_close", function() {
			$('.case_jquery').hide();

		});

		$("body").on("click", ".case_span", function() {
			$('.case_jquery').show();
			var did = $(this).attr("did")
			var uid = $(this).attr("uid")
			var url = $(this).attr("url")

			$.ajax({
				url: url + "/api/user/getChatRoomAddCaseRecordInfo2?did=" + did + "&uid=" + uid,
				success: function(result) {

					$('.case_jquery_content').html(result);



				}
			});

		});

	});

	export default {



		name: 'Chat',
		components: { 
			ShowPayInfo,
			ChatAudio,
			ChatLive,
			WorldNameCaseInfo,
			WorldCaseInfo,
			SearchDisease,
			ChatRoomAddCaseRecord,
			ChatRoomLableTemp,
			ChatRoomMenZhen,
			ChatRoomInitialTemp,
			ScrollNotice,
			ChatRoomPay,
			Record,
			Gifts,
			Schedual,
			Evaluate,
			Report,
			Comment,
			CaseInfo,
			CaseODInfo,
			LabelInfo,
			ImgCaseInfo,
			ImgNameCaseInfo
		},
		data() {
			return { 
				isDoctor:0,
				getInfo:[],
				checkVal: [], 
				//学历
				educations_list: this.educations,

				//科室
				department_list: [],
				//国际
				nationalities_list: this.nationalities,
				//民族
				races_list: this.races,
				h_num: 0,
				Locktime: '00:00:00',
				timeAlarmTWO: null,
				hour: 0,
				minute: 0,
				second: 0,
				reckon: true, // 下面的网友提示，添加上判断是否在计时


				checkedValue: [],

				checkedValue_mb: [],
				emot: false,
				all_changes: [],
				show_pay_msg: 1,
				u_type: 0,
				show_pay: 0,
				is_pay: false,
				caseList: {},
				spacks: true,
				pay_img: '',
				imageUrl: '',
				msg: 'sd',
				did_img: '',
				//left_img:+this.apiUrl+this.id_img
				id_img: '',
				did: 0,
				info: {
					isBlock: '',
					isReport: '',
					isCollected: '',
				},
				mess: '',
				text: 0,
				all_data: [],
				left_data: [],
				right_data: [],
				stompClient: '',
				timer: '',
				textarea: '',
				chatTemplates: [{
						isShow: false,
						contents: [{
								modify: false,
								value: '请输入常用语句'
							},
							{
								modify: false,
								value: '请输入常用语句'
							},
							{
								modify: false,
								value: '请输入常用语句'
							}
						]
					}

				],
				chatImgTemplates: [{
					isShow: true,
					contents: [{
							modify: false,
							value: '请输入常用语句'
						},
						{
							modify: false,
							value: '请输入常用语句'
						},
						{
							modify: false,
							value: '请输入常用语句'
						}
					]
				}],

				chatTemplateActive: null,
				duration: '00:00',
				isEnd: false,
				timeInterval: 0,
				emoj_list2: [{
						url: '&#128512;'
					},
					{
						url: '&#128513;'
					},
					{
						url: '&#128514;'
					},
					{
						url: '&#128515;'
					},
					{
						url: '&#128516;'
					},
					{
						url: '&#128517;'
					},


					{
						url: '&#128507;'
					},
					{
						url: '&#128508;'
					},
					{
						url: '&#128509;'
					},
					{
						url: '&#128510;'
					},
					{
						url: '&#128511;'
					},
					{
						url: '&#128512;'
					},



				],

				emoj_list: [{
						id: '',
						name: '拜拜',
						url: 'https://test.weimingyl.com/static/emoji/53c_1.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[拜拜]',
					}, {
						id: '',
						name: '鄙视',
						url: 'https://test.weimingyl.com/static/emoji/53c_2.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[鄙视]',
					}, {
						id: '',
						name: '打电话',
						url: 'https://test.weimingyl.com/static/emoji/53c_3.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[打电话]',
					}, {
						id: '',
						name: '打哈欠',
						url: 'https://test.weimingyl.com/static/emoji/53c_4.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[打哈欠]',
					}, {
						id: '',
						name: '大哭',
						url: 'https://test.weimingyl.com/static/emoji/53c_5.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[大哭]',
					}, {
						id: '',
						name: '大笑',
						url: 'https://test.weimingyl.com/static/emoji/53c_6.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[大笑]',
					},
					{
						id: '',
						name: '得意',
						url: 'https://test.weimingyl.com/static/emoji/53c_7.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[得意]',
					}, {
						id: '',
						name: '点赞',
						url: 'https://test.weimingyl.com/static/emoji/53c_8.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[点赞]',
					}, {
						id: '',
						name: '愤怒',
						url: 'https://test.weimingyl.com/static/emoji/53c_9.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[愤怒]',
					},
					{
						id: '',
						name: '鼓掌',
						url: 'https://test.weimingyl.com/static/emoji/53c_10.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[鼓掌]',
					}, {
						id: '',
						name: '嘿哈',
						url: 'https://test.weimingyl.com/static/emoji/53c_11.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[嘿哈]',
					}, {
						id: '',
						name: '滑稽',
						url: 'https://test.weimingyl.com/static/emoji/53c_12.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[滑稽]',
					},
					{
						id: '',
						name: '欢迎',
						url: 'https://test.weimingyl.com/static/emoji/53c_13.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[欢迎]',
					}, {
						id: '',
						name: '奸笑',
						url: 'https://test.weimingyl.com/static/emoji/53c_14.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[奸笑]',
					}, {
						id: '',
						name: '沮丧',
						url: 'https://test.weimingyl.com/static/emoji/53c_15.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[沮丧]',
					},
					{
						id: '',
						name: '开心',
						url: 'https://test.weimingyl.com/static/emoji/53c_16.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[开心]',
					}, {
						id: '',
						name: '可伶',
						url: 'https://test.weimingyl.com/static/emoji/53c_17.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[可伶]',
					}, {
						id: '',
						name: '流汗',
						url: 'https://test.weimingyl.com/static/emoji/53c_18.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[流汗]',
					},
					{
						id: '',
						name: '卖萌',
						url: 'https://test.weimingyl.com/static/emoji/53c_19.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[卖萌]',
					}, {
						id: '',
						name: '破涕为笑',
						url: 'https://test.weimingyl.com/static/emoji/53c_20.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[破涕为笑]',
					},
					{
						id: '',
						name: '敲打',
						url: 'https://test.weimingyl.com/static/emoji/53c_21.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[敲打]',
					}, {
						id: '',
						name: '胜利',
						url: 'https://test.weimingyl.com/static/emoji/53c_22.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[胜利]',
					}, {
						id: '',
						name: '调皮',
						url: 'https://test.weimingyl.com/static/emoji/53c_23.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[调皮]',
					},
					{
						id: '',
						name: '无语',
						url: 'https://test.weimingyl.com/static/emoji/53c_24.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[无语]',
					}, {
						id: '',
						name: '捏脸',
						url: 'https://test.weimingyl.com/static/emoji/53c_25.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[捏脸]',
					},
					{
						id: '',
						name: '抱抱',
						url: 'https://test.weimingyl.com/static/emoji/53c_26.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[抱抱]',
					}, {
						id: '',
						name: '摊手',
						url: 'https://test.weimingyl.com/static/emoji/53c_27.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[摊手]',
					}, {
						id: '',
						name: '比心',
						url: 'https://test.weimingyl.com/static/emoji/53c_28.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[比心]',
					},
					{
						id: '',
						name: '便便',
						url: 'https://test.weimingyl.com/static/emoji/53c_29.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[便便]',
					},
					{
						id: '',
						name: '加油',
						url: 'https://test.weimingyl.com/static/emoji/53c_30.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[加油]',
					}, {
						id: '',
						name: '礼物',
						url: 'https://test.weimingyl.com/static/emoji/53c_31.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[礼物]',
					},
					{
						id: '',
						name: '哈士奇',
						url: 'https://test.weimingyl.com/static/emoji/53c_32.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[哈士奇]',
					}, {
						id: '',
						name: '成交',
						url: 'https://test.weimingyl.com/static/emoji/53c_33.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[成交]',
					}, {
						id: '',
						name: '心碎',
						url: 'https://test.weimingyl.com/static/emoji/53c_34.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[心碎]',
					}, {
						id: '',
						name: '月亮',
						url: 'https://test.weimingyl.com/static/emoji/53c_35.gif?8',
						code: '',
						html: '',
						class: '',
						text: '[月亮]',

					},
				]
			}
		},

updated() {
		this.scrollToBottom();
	},


		created() { 
				//this.$refs.ShowPayInfo.open();
				//this.$refs.WorldNameCaseInfo.open(1,2)
				//this.$refs.showpayinfo.open();
				
			$(".chatTemplate").height("auto");

			var query = this.$route.query;
			this.did = query.did;

			// if (sessionStorage.getItem('uid') == this.did) {
			// 	window.alert("您不能自己于自己聊天！");
			// 	// 返回 false/true
			// 	// let res = confirm('您不能自己于自己聊天?');
			// 	// if(res == true) {
			// 	// 　　console.log('点击了确认')
			// 	// }else{
			// 	// 　　console.log（'点击了取消'）
			// 	// }

			// 	window.history.go(-1);

			// }
			
			//alert(sessionStorage.getItem('type'))
			if(sessionStorage.getItem('type') == 1){
				this.isDoctor = 1;
			}
			if(sessionStorage.getItem('type') == 2){
				this.isDoctor = 0;
				
			}
			if(sessionStorage.getItem('type') == 3){
				this.isDoctor = 0;
				
			}
			
			//医生保存已读信息-------------start
			const params_pay = new URLSearchParams();
			params_pay.append('uid', sessionStorage.getItem('uid'));
			params_pay.append('did', this.did);
			params_pay.append('type', sessionStorage.getItem('type'));

			axios.post(this.apiUrl + '/api/user/setEmailReader', params_pay)
				.then((response) => {


				})
				.catch(function(error) {
					console.log(error);
				});

			//医生保存已读信息--------------end





			if (this.reckon) {
				this.reckon = false;
				//  定时器，每秒
				this.timeAlarmTWO = setInterval(this.timertime, 1000);
			} else {
				this.reckon = true;
				this.second = 0;
				this.minute = 0;
				this.hour = 0;
				this.Locktime = '00:00:00';
				//  一定要关闭！！！！
				clearInterval(this.timeAlarmTWO);
				this.timeAlarmTWO = null;
			}





			this.timer = setInterval(() => {
				// 业务逻辑	 
				//患者情况下  是否支付咨询费
				if (sessionStorage.getItem('type') == 1) {
					const params_pay = new URLSearchParams();
					params_pay.append('uid', sessionStorage.getItem('uid'));
					params_pay.append('did', this.did);

					axios.post(this.apiUrl + '/api/user/getChatPay', params_pay)
						.then((response) => {
							if (response.data.code == 1) {
								if (this.show_pay_msg == 1) {
									this.show_pay_msg = 22;
									this.show_pay = 1;

									var g = new Date().getTime();
									const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + this
										.textarea + '_6_' + g;

									//alert(goMes);
									socket.emit('hello', goMes);
								}
							}

						})
						.catch(function(error) {
							console.log(error);
						});

				}

				if (sessionStorage.getItem('type') == 2) {
					this.show_pay = 1;
					this.u_type = 1;
				}


			}, 2000)







			//-------------------------------------------------------------------------

			//初始化了聊天信息 

			//this.id = query.id; 
			const params = new URLSearchParams();
			params.append('uid', sessionStorage.getItem('uid'));

			axios.post(this.apiUrl + '/api/user/getInfo', params)
				.then((response) => {
					this.id_img = this.apiUrl + response.data.data.pic;
					this.chatTemplates = response.data.data.caseUserList; 

					//this.chatImgTemplates = response.data.data.caseUserList;

				})
				.catch(function(error) {
					console.log(error);
				});
			const params2 = new URLSearchParams();
			params2.append('uid', this.did);

			axios.post(this.apiUrl + '/api/user/getInfo', params2)
				.then((response) => {
					this.did_img = this.apiUrl + response.data.data.pic;


				})
				.catch(function(error) {
					console.log(error);
				});



			const params3 = new URLSearchParams();

			params3.append('uid', this.did);
			params3.append('uuid', sessionStorage.getItem('uid'));

			axios.post(this.apiUrl + '/api/user/getInfo', params3)
				.then((response) => {
					this.info = response.data.data;

				})
				.catch(function(error) {
					console.log(error);
				});




			//this.textarea ="223";

			if (sessionStorage.getItem('type') == 1) {
				const params5 = new URLSearchParams();
				params5.append('uid', sessionStorage.getItem('uid'));
				params5.append('dct_id', this.did);

				axios.post(this.apiUrl + '/api/chat/addChatInfo', params5)
					.then((response) => {

					})
					.catch(function(error) {
						console.log(error);
					});
			}




			//-------------------------------------------------------------------------



			//一定要移除旧的message监听，否则会出现重复监听的状况
			socket.removeListener('hello');
			//这是移除所有监听
			// socket.removeAllListeners();
			socket.on('hello', (data) => {

				//聊天室病例详情



				// 发送人id_接收人id_内容
				const mes = data.split('_');








				//住id
				const uid = sessionStorage.getItem('uid');
				//对方id
				const did = query.did;
				const goMessAll = {};
				const g = mes[4];



				var date = new Date(parseInt(g))
				var Y = date.getFullYear() + '-'
				var M = (date.getMonth() + 1) + '-'
				var D = date.getDate() + ' '
				var h = date.getHours() + ':'
				var m = date.getMinutes() + ''
				var s = date.getSeconds()
				//return Y + M + D + h + m + s 
				var time = M + D + h + m;




				//接收的人是我     1文字  2普通图片    3带连接病例图片  4撤回
				
				// 6支付成功提示信息   7聊天记录信息  8支付按钮
				if (mes[1] == uid) {
	//支付按钮
					if (mes[3] == '8') {
						this.goMessAll = {
							local: 0,
							show_type: 8,
							mess: mes[2],
							wx: mes[5],
							zfb: mes[6],
							message: 'Baz',
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}
					//聊天记录信息
					if (mes[3] == '7') {
						this.goMessAll = {
							local: 0,
							show_type: 7,
							mess: mes[2],
							message: 'Baz',
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}
					//支付成功提示信息
					if (mes[3] == '6') {
						this.goMessAll = {
							local: 0,
							show_type: 6,
							mess: mes[2],
							message: 'Baz',
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}

					if (mes[3] == '3') {
						this.goMessAll = {
							local: 0,
							show_type: 3,
							mess: mes[2],
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}

					if (mes[3] == '2') {
						this.goMessAll = {
							local: 0,
							show_type: 2,
							mess: mes[2],
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}


					if (mes[3] == '1') {
						this.goMessAll = {
							local: 0,
							show_type: 1,
							mess: mes[2],
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}



					//撤回
					if (mes[3] == '4') {
						$('#' + mes[2]).hide();
					}

				}


				// //发送的人是我  1文字  2普通图片    3带连接病例图片  4撤回
				if (mes[0] == uid) {
					// 聊天记录信息
					if (mes[3] == '7') {
						this.goMessAll = {
							local: 1,
							show_type: 7,
							mess: mes[2],
							message: 'Baz',
							ids: g,
							time: time,

						};
						this.all_data.push(this.goMessAll);
					}


					//支付成功后的回调提示
					if (mes[3] == '6') {
						this.goMessAll = {
							local: 1,
							show_type: 6,
							mess: mes[2],
							message: 'Baz',
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}
					if (mes[3] == '3') {
						this.goMessAll = {
							local: 1,
							show_type: 3,
							mess: mes[2],
							message: 'Baz',
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}

					if (mes[3] == '2') {
						this.goMessAll = {
							local: 1,
							show_type: 2,
							mess: mes[2],
							message: 'Baz',
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}

					if (mes[3] == '1') {
						this.goMessAll = {
							local: 1,
							show_type: 1,
							mess: mes[2],
							message: 'Baz',
							ids: g,
							time: time,
						};
						this.all_data.push(this.goMessAll);
					}


				}


				//情况 输入框 聊天啊信息
				this.textarea = "";
			});

			var g = new Date().getTime();
			const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + this
				.textarea + '_7_' + g;

			//alert(goMes);
			socket.emit('hello', goMes);

		},
		mounted() {


			console.log('aa');

			// 开始计时
			let time = 0
			this.timeInterval = setInterval(() => {
				time++
				let m = Math.floor(time / 60)
				if (m >= 1) {
					this.isEnd = true
				}
				let s = time % 60
				m = this.toDuble(m)
				s = this.toDuble(s)
				this.duration = m + ':' + s

			}, 1000)
			this.$nextTick(() => {
				this.$refs['scroll'].wrap.scrollTop = this.$refs['scroll'].wrap.scrollHeight;
			})
		},
		beforeDestroy() {
			clearInterval(this.timeInterval)
		},

		mounted() {

			 this.$nextTick(() => {
				 
				 let box = this.$el.querySelector(".chat-box-content");
				 
				  // 设置scrollTop
				  this.$refs.scrollContainer.scrollTop = 1111;
				});
		},


		methods: {
			
			getScrollPosition() {
			      // 使用 this.$refs 获取DOM元素
			      const div = this.$refs.myDiv;
			      // 获取滚动位置
			      const scrollTop = div.scrollTop;
			      console.log('Scroll Position:', scrollTop);
			    },
				
			// scrollToBottom () {
			//       this.$nextTick(() => {
			//         setTimeout(() => {
			//           var scrollTop = this.$el.querySelector('.el-scrollbar')
			//           scrollTop.scrollTop = scrollTop.scrollHeight
			// 		  console.log(scrollTop.scrollHeight)
			//         }, 13)
			//       })
			//     },
			scrollToBottom() {
				this.$nextTick(()=> {
					let box = this.$el.querySelector(".chat-box-content");
					box.scrollTop = 100000; 
					console.log(box.scrollHeight)
				})		
			},
 
			
			copyMsgToServer(){
				
			},
			
			show_Pay_info(wx,zfb){   
				this.$refs.ShowPayInfo.open(wx,zfb)
			},
			addPatient2(id) {
				this.$refs.WorldNameCaseInfo.open(id,2)
			},
			
			addPatient(id) { 
				this.$refs.WorldCaseInfo.open(id,2)
			},
			
			
			
			
			
			//修改
			mb_use() {


				var text = '';
				for (let i in this.checkVal) {

					text += this.chatTemplates[this.chatTemplateActive].contents[i].value;

				}



				this.textarea = text;
			},

			//修改
			mb_copy() {

				var text = '';
				for (let i in this.checkVal) {

					text += this.chatTemplates[this.chatTemplateActive].contents[i].value;

				}



				// text是复制文本
				// 创建input元素
				const el = document.createElement('input')
				// 给input元素赋值需要复制的文本
				el.setAttribute('value', text)
				// 将input元素插入页面
				document.body.appendChild(el)
				// 选中input元素的文本
				el.select()
				// 复制内容到剪贴板
				document.execCommand('copy')
				// 删除input元素
				document.body.removeChild(el)
				alert('复制成功')


			},


			//模板保存
			mb_save(index, id) {
				this.saveTemplate(index, id);
				alert('保存成功');
			},


			//模板删除
			mb_del() {


				this.checkVal.forEach((item) => {
					this.chatTemplates[this.chatTemplateActive].contents.splice(item, 1);
				});




			},
			//添加快捷聊天模板 
			addMb(e) {
				//$arr['value'] = ''; 
				this.chatTemplates[e].contents.push({
					modify: false,
					value: '请输入常用语句'
				});



				//alert(this.chatTemplates[e].contents[0].value)


			},
			goMesBoxUp() {

				alert('已经是最后一位!');
			},
			goMesBoxNext() {

				alert('已经是最后一位!');
			},
			///inbox

			historyLog() {

				//$('.more_msg').hide()
				this.h_num++;

				const params2 = new URLSearchParams();

				params2.append('uid', sessionStorage.getItem('uid'));
				params2.append('did', this.did);
				params2.append('type', sessionStorage.getItem('type'));
				params2.append('num', this.h_num);
				params2.append('content', this.textarea);
				axios.post(this.apiUrl + '/api/User/getChatLog', params2)
					.then((res) => {

						//res = JSON.parse(res.data); 
						console.log('111111112333333333333')
						console.log(res.data.data)
						console.log('111111112333333333333')
						//this.all_data = res.data.data;
						if (res.data.data.length == 0) {
							alert('已无聊天记录');
						}
						for (let i in res.data.data) {
							this.all_data.unshift(res.data.data[i]);
							// text += $('.mes_' + this.checkedValue[i]).html();
							// $('.check_all').hide(); 
						}


						var g = new Date().getTime();
						const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + this
							.textarea + '_7_' + g;
						socket.emit('hello', goMes);
					})
					.catch(function(error) {
						console.log(error);
					});

			},

			goMessage() {
				window.open('/#/inbox');
			},

			goCleanBox() {

				this.$confirm('确认删除该, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					window.location.replace('/')
				}).catch(() => {

				});

			},

			// 页面元素转图片
			toImage() {
				// 手动创建一个 canvas 标签
				const canvas = document.createElement("canvas")
				// 获取父标签，意思是这个标签内的 DOM 元素生成图片
				// imageTofile是给截图范围内的父级元素自定义的ref名称
				let canvasBox = this.$refs.imageTofile
				// 获取父级的宽高
				const width = parseInt(window.getComputedStyle(canvasBox).width)
				const height = parseInt(window.getComputedStyle(canvasBox).height)
				// 宽高 * 2 并放大 2 倍 是为了防止图片模糊
				canvas.width = width * 2
				canvas.height = height * 2
				canvas.style.width = width + 'px'
				canvas.style.height = height + 'px'
				const context = canvas.getContext("2d");
				context.scale(2, 2);
				const options = {
					backgroundColor: null,
					canvas: canvas,
					useCORS: true
				}
				html2canvas(canvasBox, options).then((canvas) => {
					// toDataURL 图片格式转成 base64
					let dataURL = canvas.toDataURL("image/png")
					console.log(dataURL)
					this.downloadImage(dataURL)
				})
			},
			//下载图片
			downloadImage(url) {
				// 如果是在网页中可以直接创建一个 a 标签直接下载 
				let a = document.createElement('a')
				a.href = url
				a.download = '首页截图'
				a.click()
			},




			copyMore() {
				var text = '';
				for (let i in this.checkedValue) {

					text += $('.mes_' + this.checkedValue[i]).html();
					$('.check_all').hide();

				}



				// text是复制文本
				// 创建input元素
				const el = document.createElement('input')
				// 给input元素赋值需要复制的文本
				el.setAttribute('value', text)
				// 将input元素插入页面
				document.body.appendChild(el)
				// 选中input元素的文本
				el.select()
				// 复制内容到剪贴板
				document.execCommand('copy')
				// 删除input元素
				document.body.removeChild(el)
				alert('复制成功')



			},
			moreDel() {

				this.$confirm('确认删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					for (let i in this.checkedValue) {

						$('.del_' + this.checkedValue[i]).remove();
						$('.check_all').hide();

					}
				}).catch(() => {

				});

			},
			allMore() {

				$('.del_all').prop("checked", true);

			},
			allClean() {
				this.$confirm('确认清空, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					$('.del_all').remove();
				}).catch(() => {

				});
			},
			moreChange() {

				$('.del_all').show();
				console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaa')
				//alert( this.checkedValue[0])
				console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaa')
			},
			timertime() {
				this.second++;
				if (this.second >= 60) {
					this.second = 0;
					this.minute = this.minute + 1;
				}
				if (this.minute >= 60) {
					this.minute = 0;
					this.hour = this.hour + 1;
				}
				let h = this.hour < 10 ? '0' + this.hour : this.hour;
				let m = this.minute < 10 ? '0' + this.minute : this.minute;
				let s = this.second < 10 ? '0' + this.second : this.second;
				this.Locktime = h + ":" + m + ":" + s;
			},


			emotStart() {
				if (this.emot) {

					this.emot = false;
				} else {
					this.emot = true;

				}
			},

			//停止弹窗
			stopT() {
				this.emot = false;
			},

			//发送表情
			goEmoj(e) {
				// var ms = this.emoj_list[e]
				// this.textarea += '&#128512; &#128516; &#128525; &#128151;'

				this.textarea += e
			},

			//保存moan
			saveTemplate(e, id) {
				// alert(e)
				// alert(id) 
				console.log("---------------------");
				console.log(this.chatTemplates[e]['contents']);
				console.log("---------------------");
				const params = new URLSearchParams();
				params.append('id', id);
				params.append('mesa', JSON.stringify(this.chatTemplates[e]['contents']));
				axios.post(this.apiUrl + '/api/User/saveTemplate', params)
					.then((response) => {

					})
					.catch(function(error) {
						console.log(error);
					});


			},

			//保存 门诊病理
			saveMenzhen() {
				const params = new URLSearchParams();

				params.append('y_id', sessionStorage.getItem('uid'));
				params.append('h_id', this.did);
				params.append('mes', this.textarea);
				axios.post(this.apiUrl + '/api/User/saveMenzhen', params)
					.then((response) => {
						alert("门诊病理 保存成功!");
						this.textarea = '';
					})
					.catch(function(error) {
						console.log(error);
					});
			},


			//保存 住院病理
			saveZhuYuan() {
				const params = new URLSearchParams();
				params.append('y_id', sessionStorage.getItem('uid'));
				params.append('h_id', this.did);
				params.append('mes', this.textarea);
				axios.post(this.apiUrl + '/api/User/saveZhuYuan', params)
					.then((response) => {
						alert("住院病理 保存成功!");
						this.textarea = '';
					})
					.catch(function(error) {
						console.log(error);
					});
			},

			cleanMsgToServer() {
				this.textarea = '';
			},

			go_live() {
				let partNumbers = ''

				const {
					href
				} = this.$router.resolve({
					// path 路由器配置
					path: `live`,
					query: {
						//  传参
						'partNumbers': 12
					}
				})
				//打开空白页面
				window.open(href, '_blank')
			},

			//发送图片 被子页面调用
			goSendImgs(d, file_id) {

				const params = new URLSearchParams();
				params.append('lines', d);
				params.append('file_id', file_id);

				axios.post(this.apiUrl + '/api/User/getGoSendImg', params)
					.then((response) => {
						for (let i in response.data.data) {


							var g = new Date().getTime();
							var res = this.apiUrl + response.data.data[i];
							const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + res + '_2_' + g;
							//alert(goMes);
							socket.emit('hello', goMes);


							// this.fileList.push({
							// 	"url": this.apiUrl+response.data.data[i].content
							// })
						}

					})
					.catch(function(error) {
						console.log(error);
					});


			},
			//双击打开右键图片窗口
			addImgPatient(id) {
				//alert(id)
				this.$refs.ImgCaseInfo.open(id)
			},

			//打开右键图片窗口
			addImgPatient2(id) {
				this.$refs.ImgNameCaseInfo.open(id)
			},


			//添加文字模板

			//修改文字模板 
			changeTemplate(id) {
				this.$refs.WorldNameCaseInfo.open(id)
			},
			//文字模板
			showTemplate(id) {
				this.$refs.WorldCaseInfo.open(id)

			},

			//邮件删除
			delImg(id) {

				var g = new Date().getTime();
				// alert(g)
				// alert(id)
				// alert(g - id)
				if ((g - id) > 180000) {
					this.$message.success('超过三分钟 不可撤回');
				} else {


					this.$confirm('确认撤回?', '', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'info'
					}).then(() => {
						$('#' + id).hide();


						const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + id + '_4';
						socket.emit('hello', goMes);

						// const  index = this.tasks.findIndex(funciton(ele){
						//           return ele.id == id
						//    });
						// //假设没有找到
						// if(index === -1){
						//      return alert('删除失败');
						// }
						// //删除元素
						// this.tasks.splice(index,1);

					}).catch(() => {});
				}
			},


			//门诊病例发送
			goOutpatientCases(img) {

				var g = new Date().getTime();
				const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + img + '_3_' + g;

				socket.emit('hello', goMes);

			},


			//疾病搜索
			goSearchDisease() {
				this.$refs.searchDisease.open(22);
			},



			//发送付款二维码 
			getPayImg(data) {
				
				var a = eval(data);
				var price = a.price;
				var wx = a.wx;
				var zfb = a.zfb;
				
				
				var g = new Date().getTime();
				//const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + data + '_2_' + g;
				const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + price + '_8_' + g+'_'+a.wx+'_'+a.zfb;

				socket.emit('hello', goMes);
			},


			//医生添加病例
			addCaseRecord(e) {
				//e 1门诊病理  2 住院病理

				this.$refs.ChatRoomAddCaseRecord.open(this.did, e);
			},

			setBlock() {
				// alert('11');
			},


			//视频聊天室
			goLive() {
				this.$refs.ChatLive.open(this.did);
			},

			//语音聊天室
			goAudio() {
				this.$refs.ChatAudio.open(this.did);
			},




			//标签模板
			goLableTemp() {
				this.$refs.ChatRoomLableTemp.open(22);
			},
			//初始化模板
			goInitialTemp() {
				console.log("---------------------------------");
				console.log(this.$refs);
				this.$refs.ChatRoomInitialTemp.open(22);
				//this.$refs.ChatRoomInitialTemp.open();  
			},
			goEvaluate() {
				this.$refs.Evaluate.open()
			},
			sendGifts() {
				this.$refs.gifts.open()
			},

			goComment() {
				this.$refs.Comment.open()
			},

			goReport() {
				this.$refs.Report.open()
			},

			/**
			 * 收藏的操作
			 */
			saveCollect2() {
				const params = new URLSearchParams();


				params.append('uid', sessionStorage.getItem('uid'));
				params.append('bid', this.did);


				axios.post(this.apiUrl + '/api/User/addCollection', params)
					.then((response) => {

						this.$message.success('操作成功');
						this.info.isCollected = 1;


					})


					.catch(function(error) {
						console.log(error);
					});
			},

			enterSearch() {
				document.onkeydown = e => {
					//13表示回车键，baseURI是当前页面的地址，为了更严谨，也可以加别的，可以打印e看一下
					if (e.keyCode === 13 && e.target.baseURI.match(/freshmanage/)) {
						// alert(23);
						//回车后执行搜索方法
						this.sendMsgToServer()
					}
				}
			},
			// 上传图片
			upload(e) {
				//这是参数配置，可以忽略
				const tthash = window.btoa(
					window
					.btoa(encodeURI(location.hash.replace('#/', '') ?? ''))
					.split('')
					.reverse()
					.join('')
				)
				//使用FormData 的最大优点就是可以异步上传二进制文件。
				//而图片，文件之类的文件是二进制，接口接收参数也是接收的二进制
				//可以先通过new关键字创建一个空的 FormData 对象，然后使用 append() 方法向该对象里添加字段
				//（字段的值可以是一个 Blob 对象，File对象或者字符串，剩下其他类型的值都会被自动转换成字符串）。

				//也可以用一个已有的form元素来初始化FormData对象

				this.getBase64(e.file).then((res) => {

					var g = new Date().getTime();
					const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + res + '_2_' + g;
					//alert(goMes);
					socket.emit('hello', goMes);

					const formData = new FormData()


					//这里是把上传接口所有的参数都append到formData中
					formData.append('img', res)
					formData.append('uid', this.uid)

					const config = {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}

					//接口调用
					// axios.post(this.apiUrl+'/api/user/upImg', formData, config).then((res) => {
					//     this.imageUrl = this.apiUrl+res.data.data.pic;
					//     console.log(this.imageUrl);
					//     //this.from.logo = res.data.data.filename
					// })


				})

			},

			getBase64(file) {
				return new Promise(function(resolve, reject) {
					const reader = new FileReader()
					let imgResult = ''
					reader.readAsDataURL(file)
					reader.onload = function() {
						imgResult = reader.result
					}
					reader.onerror = function(error) {
						reject(error)
					}
					reader.onloadend = function() {
						resolve(imgResult)
					}
				})
			},


			sendMsgToServer() {
				alert(this.textarea)
				this.getScrollPosition();
				if (!this.textarea) {
					return;
				}

				var g = new Date().getTime();
				const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + this.textarea + '_1_' + g;

				//alert(goMes);
				socket.emit('hello', goMes);
				//socket.emit('hello',"1111111111111");
				// this.right_data.push('23_23_'+this.textarea) ;

				//给医生发送邮箱


				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('did', this.did);
				params.append('type', sessionStorage.getItem('type'));
				params.append('content', this.textarea);

				axios.post(this.apiUrl + '/api/user/setEmail', params)
					.then((response) => {
						console.log(response.data.data);

						//this.info = response.data.data;  

					})
					.catch(function(error) {
						console.log(error);
					});


				const params2 = new URLSearchParams();
				params2.append('uid', sessionStorage.getItem('uid'));
				params2.append('did', this.did);
				params2.append('type', sessionStorage.getItem('type'));
				params2.append('content', this.textarea);

				axios.post(this.apiUrl + '/api/user/setChatLog', params2)
					.then((response) => {
						console.log(response.data.data);

						//this.info = response.data.data;  

					})
					.catch(function(error) {
						console.log(error);
					});


				//sessionStorage.getItem('type')
				
				
				//this.scrollToBottom();


			},


			format1() {
				return '10分'
			},
			format2() {
				return '10分'
			},

			// 点击资金按钮
			handlePay() {

				//alert(1)
				this.$refs.ChatRoomPay.open(this.did)

				$('textarea').removeAttr("readonly");

				this.spacks = true;


			},


			// 增加聊天图片模板
			addImgTemplate() {
				if (this.chatImgTemplates.length < 4) {
					this.chatImgTemplates.push(chatTemplate())
				}
			},



			// 增加聊天消息模板   文字模板
			addTemplate() {

				const params = new URLSearchParams();
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('type', 2);
				axios.post(this.apiUrl + '/api/user/addCaseTemp', params)
					.then((response) => {
						this.chatTemplates = response.data.data;
					})
					.catch(function(error) {
						console.log(error);
					});

			},


			// 点击消息模板
			chooseTemplate(index, id) {
				if (this.chatTemplateActive == index) {
					this.chatTemplateActive = null
				} else {
					this.chatTemplateActive = index
				}

			},
			openRecord() {
				this.$refs.record.open()
			},
			// 一位变两位
			toDuble(n) {
				if (n < 10) {
					n = '0' + n
				}
				return n
			},



			// format1() {
			//   return '4.5分'
			// },
			// format2() {
			//   return '4.9分'
			// },
			// // 点击资金按钮
			// // handlePay(){
			// //   this.$refs.pay.open()
			// // },
			// // 增加聊天消息模板
			// addTemplate(){
			//   if (this.chatTemplates.length < 3) {
			//     this.chatTemplates.push(chatTemplate())
			//   }
			// },
			// // 点击消息模板
			// chooseTemplate(index){
			//   if (this.chatTemplateActive == index){
			//     this.chatTemplateActive = null
			//   } else {
			//     this.chatTemplateActive = index
			//   }
			// },
			// openRecord(){
			//   this.$refs.record.open()
			// },
			// // 一位变两位
			// toDuble(n){
			//   if (n<10){
			//     n = '0' + n
			//   }
			//   return n
			// },
		}
	}
</script>

<style scoped lang="scss">
	
	.chat-box-content::-webkit-scrollbar { height: 0 !important;width:0px !important; }
	
	.footer {
	        position: fixed;
	        bottom: 0;
	    }
	
	
	.borderNone /deep/ .el-input__inner {
		border: none;
		background: #F4FEFF;
	}

	.mb_b button {
		margin: 0;
		padding: 3px 10px;
		background: #6CDDE5;
		margin-top: 10px;
		color: white;
	}

	.reght_css {
		background-color: #009688 !important;
		color: white !important;
	}

	.bubble::before {

		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 8px 0;
		border-color: transparent #F7F7F7 transparent transparent;
		content: '\A';
		position: absolute;
		left: 0px;
		top: 25px;
		border-right-color: red;

		z-index: 999999;
	}


	.chatWrap {
		width: 1200px;
		margin: 32px auto;
		display: flex;

		.chatMain {
			width: 1200px;

			.head {
				height: 162px;
				width: 100%;
				padding: 8px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				background-color: #81D3F7;

				.avatar {
					width: 128px;
					height: 146px;
					border-radius: 6px;
					overflow: hidden;
					margin-right: 25px;
				}

				.info {
					width: 835px;
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					line-height: 17px;

					.el-col {}

					.nick {
						font-size: 16px;
						font-weight: 600;
						color: #333333;
						line-height: 22px;
						margin-right: 12px;
					}

					.name {
						margin-right: 29px;
					}

					.id {
						margin-right: 31px;
					}

					.lastTime {}

					.rate_item {
						margin: 8px 0;

						span {
							font-size: 12px;
						}

						.el-progress {
							display: inline-block;
						}
					}

					.info_detail {
						margin: 8px 0;
					}
				}

				.videoCall {
					position: relative;
					width: 215px;
					height: 100%;
					text-align: right;

					.video {
						position: absolute;
						right: 9px;
						top: 4px;
						width: 164px;
						height: 96px;
						background: #FFFFFF;
						border-radius: 10px;
					}

					.nav {
						width: 215px;
						height: 20px;
						font-size: 14px;
						font-weight: 500;
						color: #666666;
						line-height: 20px;
						position: absolute;
						bottom: 12px;
						right: 9px;

						.nav_item {
							cursor: pointer;
						}

						.el-divider--vertical {
							height: 10px;
							width: 2px;
							margin: 0 5px;
							background-color: #999;
							vertical-align: unset;
						}
					}
				}
			}

			.appendHead {
				width: 1200px;
				height: 82px;
				padding: 4px 8px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				background-color: #81D3F7;
				margin-top: 1px;

				.appendAvatar {
					width: 64px;
					height: 75px;
					border-radius: 6px;
					overflow: hidden;
					margin-right: 18px;
				}

				.appendInfo {
					width: 1100px;
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					line-height: 22px;

					&>div {
						width: 100%;
					}
				}
			}

			.warning {
				width: 100%;
				height: 29px;
				background: #FEEDD9;
				padding-left: 46px;
				font-size: 12px;
				font-weight: 600;
				color: #0070FF;
				line-height: 29px;
				box-sizing: border-box;
			}

			.newsWrap {
				height: 570px;
				background-color: #fff;
				/*padding: 40px;*/
				box-sizing: border-box;
				border: 1px solid rgba(151, 151, 151, 0.3);
				position: relative;

				.newsList {
					//min-height: 100%;
					// width: 1120px;
					padding:10rpx;
					margin: 40px auto;

					.news {
						display: flex;
						margin-bottom: 30px;
						position: relative;

						.el-image {
							width: 42px;
							height: 47px;
						}

						.news_avatar_append {
							position: absolute;
							right: -24px;
							width: 24px;
							height: 24px;
							bottom: 4px;
						}

						.news_content {

							display: flex;
							flex-direction: column;

							.time {
								font-size: 12px;
								font-weight: 600;
								color: #999999;
								line-height: 17px;
							}

							.text {
								max-width: 500px;
								background: #F2F5FA;
								padding: 7px 15px;
								font-size: 14px;
								font-weight: 400;
								color: #333330;
								line-height: 20px;
								position: relative;

								&:before {
									content: '';
									width: 0;
									height: 0;
									border: 7px solid transparent;
									position: absolute;
									left: -14px;
									top: 50%;
									transform: translateY(-50%);
								}
							}
						}

					}

					.left {


						.el-image {
							margin-right: 15px;
						}

						.text:before {
							border-right-color: #F2F5FA !important;
							left: -14px !important;
						}
					}

					.right {
						flex-direction: row-reverse;
						text-align: right;

						.el-image {
							margin-left: 15px;
						}

						.text:before {
							border-left-color: #009688 !important;
							left: 100% !important;
						}
					}
				}

				.newEnd {
					padding-bottom: 100px;
				}

				.beforeEnd {
					position: relative;
					bottom: 20px;
					padding-left: 410px;

					p {
						font-size: 12px;
						line-height: 17px;
					}

					.navBtn {
						padding: 4px 0;
						// background-color: $green;
					}
				}
			}

			.send_tools_bar {
				width: 1200px;
				height: auto;
				background: #F0F0F0;
				display: flex;
				justify-content: space-between;
				font-size: 20px;
				line-height: 50px;

				.left_tools {
					/*width: 360px;*/
					display: flex;
					align-items: center;
					height: 50px;

					i {
						margin-left: 10px;
					}

					.icon8 {
						display: inline-block;
						width: 24px;
						height: 26px;
						margin-left: 40px;
					}

					.left_tools_item {
						float: left;
						position: relative;

						.chatTemplate {
							position: absolute;
							width: 522px;
							height: 155px;
							background: #F4FEFF;
							border: 1px solid #76D8DF;
							bottom: 55px;
							left: 10px;

							.template_item {
								display: flex;
								padding: 0 14px;
								justify-content: space-between;
							}

							&:before {
								content: '';
								width: 0;
								height: 0;
								border: 10px solid;
								border-color: #76D8DF transparent transparent transparent;
								position: absolute;
								bottom: -21px;
								left: 30px;
							}

							&:after {
								content: '';
								width: 0;
								height: 0;
								border: 10px solid;
								border-color: #F4FEFF transparent transparent transparent;
								position: absolute;
								bottom: -19px;
								left: 30px;
							}
						}
					}
				}

				.right_tools {
					margin-right: 40px;
					font-size: 24px;
				}
			}

			.input_wrap {
				position: relative;

				.send {
					position: absolute;
					bottom: 0px;
					right: 0px;
					background-color: #fff;
					text-align: right;
					padding: 10px 30px 30px;
					width: calc(100% - 40px);
					margin: 0 30px 1px 10px;
					box-sizing: border-box;

					small {
						color: #999999;
					}
				}
			}
		}

		.chatSide {
			.side_top {
				width: 100px;

				.timeCount {
					margin-top: 20px;
				}
			}

			.side_middle {
				margin-top: 190px;
			}
		}

	}

	.info /deep/ .el-progress-bar__inner {
		background-color: $green !important;
	}

	.info /deep/ .el-progress-bar {
		width: 150px;
	}

	.info /deep/ .el-progress__text {
		font-size: 12px !important;
	}

	.input_wrap /deep/ .el-textarea__inner {
		font-size: 14px;
		padding-bottom: 70px;
	}

	.input_wrap /deep/ .el-textarea__inner:focus {
		border-color: #DCDFE6;
	}

	.input_wrap /deep/ .el-button--small,
	.el-button--small.is-round {
		width: 78px;
		height: 32px;
		background-color: #fff !important;
		color: $green;
		border-radius: 16px !important;
		margin-left: 15px;
	}

	.side_top /deep/ .el-button {
		background-color: transparent !important;
		color: #666;
		border-color: #D9D9D9 !important;
		width: 82px;
		height: 35px;
		margin-left: 18px;
	}

	.side_middle /deep/ .el-button {
		background-color: transparent !important;
		color: #666;
		border-color: #D9D9D9 #D9D9D9 #D9D9D9 transparent !important;
		width: 59px;
		height: 28px;
		font-size: 12px;
	}

	.template_item /deep/ .el-input {
		width: 100%;
	}

	.template_item /deep/ .el-button {
		color: $green;
	}

	/deep/ .el-scrollbar__wrap {
		overflow-x: hidden !important;
	}
</style>